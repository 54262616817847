import {createRouter, createWebHashHistory} from "vue-router";

export const routes = [
    {
        path: '/',
        component: () => import('@/pages/index/home'),
    },
    {
        path: '/home',
        component: () => import('@/pages/index/home'),
    },
    {
        path: '/sale',
        component: () => import('@/pages/index/sale'),
    },
    {
        path: '/wbl',
        name: 'wbl',
        component: () => import('@/pages/index/wbl'),
    },
    {
        path: '/tb',
        component: () => import('@/pages/index/tb'),
    },
    {
        path: '/limit',
        component: () => import('@/pages/index/limit'),
    },
    {
        path: '/saleAll',
        component: () => import('@/pages/index/saleAll'),
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/pages/index/profile'),
    },
    {
        path: '/detail/sale/:id',
        component: () => import('@/pages/index/detail/sale'),
    },
    {
        path: '/detail/wbl/:id',
        component: () => import('@/pages/index/detail/wbl'),
    },
    {
        path: '/detail/tb/:id',
        component: () => import('@/pages/index/detail/tb'),
    },
    {
        path: '/detail/limit/:id',
        component: () => import('@/pages/index/detail/limit'),
    },
    {
        path: '/sale/index/:id',
        component: () => import('@/pages/index/sale/index'),
    },
    {
        path: '/sale/detail/:id',
        component: () => import('@/pages/index/sale/detail'),
    },
]

export default createRouter({
    history: createWebHashHistory(),
    routes: routes
})