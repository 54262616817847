import "core-js/stable";
import "regenerator-runtime/runtime";

import {createApp} from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import 'ant-design-vue/dist/antd.css';
import router from '@/router'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

const app = createApp(App);


import {message} from "ant-design-vue";
message.config({
    top: `400px`,
});

app.use(Antd).use(router).use(VueViewer).mount('#app');
