<template>
  <div>
    <Layout></Layout>
  </div>
</template>

<script>
import Layout from '@/components/layout/index'
export default {
  name: 'App',
  components: {
    Layout
  }
}
</script>

<style>
</style>
