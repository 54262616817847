<template>
  <a-row>
    <a-col :span="6" @click="clickJump()">
      <a-row>
        <a-col :span="6">
          <a-image
              :width="70"
              :height="70"
              :src="logo"
              :preview="false"
          />
        </a-col>
        <a-col :span="18">
          <div>
            <a-typography-title :level="4" style="font-size: 23px">剑三商会</a-typography-title>
          </div>
          <div style="font-size: 12px">www.jx3sh.com</div>
        </a-col>
      </a-row>
    </a-col>
    <a-col :span="12">
      <div style="width:100%;height: 100%; display: flex;justify-content: space-around;align-items: center;">
        <div id="inputWrapper" style="width: 90%;border: 2px solid #A8A9BB;border-radius: 30px;">
          <search-outlined :style="{
          fontSize: '20px'
        }" style="position: absolute; z-index: 3;top: 10px;left: 10px;"/>
          <input id="input" type="search" v-model="keyword" autocomplete="off" placeholder="请输入WBL编号" @keyup.enter="clickEnter" style="color: #000">
        </div>
      </div>
    </a-col>
    <a-col :span="6">
      <div style="height: 100%; display: flex;justify-content: space-around;align-items: center;">
        <div v-if="!userInfo" class="href"  @click="clickLogin">快速登录</div>
        <div v-if="userInfo" style="color: #363D62;"><span style="margin-right: 5px">{{userInfo.nickname}}</span ><span class="href" @click="clickLogout">登出</span> </div>
        <div class="href">联系站长</div>
      </div>
    </a-col>
  </a-row>
  <div v-if="show" style="display: flex;justify-content: space-around;margin-top: 10px;padding: 20px 100px;padding-bottom: 10px">
    <div v-for="(item, index) in list" :key="index" class="nav_btn" :class="{'nav_btn_active': (page === '/' + item.value || page.split('/').slice(0,3).join('/') === '/detail/' + item.value )}" @click="handleClickJump(item.value)">
      {{ item.label }}</div>
  </div>
</template>

<script>
import {
  message,
} from 'ant-design-vue';
import logo from '@/assets/logo.png'
import {
  SearchOutlined
} from '@ant-design/icons-vue';
import { useRouter } from 'vue-router'
import { watch } from 'vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  components: {
    SearchOutlined
  },
  data() {
    return {
      logo,
      page: 'home',
      show: true,
      list: [
        {
          label: '首页',
          value: 'home',
        },
        {
          label: '搜DS号',
          value: 'sale',
        },
        {
          label: '搜WBL',
          value: 'wbl',
        },
       // {
       //   label: '搜贴吧号',
       //   value: 'tb',
       // },
      //   {
      //     label: '陪玩连麦',
      //     value: 'sqlt',
      //   },
        {
          label: '限量外观',
          value: 'limit',
        },
        {
          label: 'DS大全',
          value: 'saleAll',
        },
        {
          label: '个人中心',
          value: 'profile',
        }
      ],
      userInfo: null,
      keyword: null
    }
  },
  setup() {
    const router = useRouter();

    return {
      watchRouter: (self) => watch(() => router.currentRoute.value.path,(page) => {
        console.log('page >>> ', page)
        if (page === '/') {
          self.page = '/home'
        } else {
          self.page = page
        }

        if (page.split('/').slice(0,3).join('/') === '/sale/index' || page.split('/').slice(0,3).join('/') === '/sale/detail') {
          self.show = false
        } else {
          self.show = true
        }

      }, { deep: true })
    }
  },
  mounted() {
    this.watchRouter(this)

    if (localStorage.getItem('cache_token')) {
      this.userInfo = localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info')) : null
    }
  },
  methods: {
    handleClickJump(page) {
      console.log('page is : ', page);

      if (page === 'sqlt') {
        window.open('https://f7178.com/H5wai/#/pages/component/component', '_blank');
        return
      }

      this.$router.push('/' + page)
    },
    clickLogin() {
      this.$router.push('/profile')
    },
    clickLogout() {
      localStorage.clear()
      message.success('退出成功', 3)
      // this.$router.push('/home')
      location.reload();

    },
    clickEnter() {
      console.log(this.keyword)
      if (!this.keyword) {
        message.warn("请输入WBL编号")
      }
      this.$router.push({
        name: `wbl`,
        params: {
          keyword: this.keyword
        }
      })
    },
    clickJump() {
      this.$router.push({
        path: `/`
      });
    }
  }
}
</script>
<style scoped lang="scss">

/deep/ .ant-image-img {
  width: 100% !important;
  height: 100% !important;
  vertical-align: middle;
}

</style>
<style>

.href:hover {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}

.nav_btn {
  width: 100px;
  height: 40px;
  background-color: #fff;
  color: #373e5f;
  text-align: center;
  line-height: 40px;
  border-radius: 30px;
  font-size: 18px;
}

.nav_btn_active {
  background-color: #d65951;
  color: #fff;
}

#inputWrapper {
  height: 70%;
  position: relative;
}

input {
  background-color: white;
  border: none;
  border-radius: 50px;
  color: white;
  font-family: inherit;
  font-size: inherit;
  height: 100%;
  outline: none;
  padding-inline-end: 44px;
  padding-inline-start: 52px;
  position: relative;
  width: 100%;
}


</style>

