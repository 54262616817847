<template>
  <div style="background-color: #d65951;padding: 10px 20px;color: white;">
    <a-row>
      <a-col :span="5">
        <div style="width: 100%;display: flex;flex-direction: column;justify-content: space-between;align-items: left;">
          <span style="margin: 10px 0px;">剑三商会 www.jx3sh.com</span>
        </div>
      </a-col>
      <a-col :span="14">
          <div style="width: 100%;display: flex;flex-direction: column;justify-content: space-between;align-items: center;">
            <span style="margin: 10px 0px;">剑三商会网站仅做数据展示，不提供交易、担保等业务 商务合作联系QQ: 498569834。务必备注来意</span>
          </div>
      </a-col>
			<div style="display: flex;flex-direction: column;justify-content: space-between;align-items: right;">
				<span style="margin: 10px 0px;"><a  style ="text-decoration: none;color:white!important" href="https://beian.miit.gov.cn" target="_blank" >ICP备案号：鲁ICP备19016203号-1</a></span>
			</div>
			
    </a-row>

  </div>
</template>

<script>
import logo from '@/assets/logo.png'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  components: {

  },
  data() {
    return {
      logo
    }
  },

}
</script>

<style>

</style>

