<template>
  <a-config-provider :locale="locale">
    <div style="display: flex;justify-content: center;align-items: flex-start;background-color: #eeeeee;">
      <div style="min-width: 1300px;max-width: 1300px">
        <a-layout>
          <a-layout-footer style="padding: 20px;padding-bottom: 0;background-color: #fff;">
            <Header/>
          </a-layout-footer>

          <a-divider style="border-color: #eee;height: 5px;" />

          <a-layout-content style="background-color: #fff;min-height: calc(100vh - 427px);">
            <router-view></router-view>
          </a-layout-content>

          <a-layout-footer style="padding: 20px 0;background-color: #fff;">
            <Footer/>
          </a-layout-footer>
        </a-layout>
      </div>

      <div style="position: fixed;top: 30%;right:20px;width: 80px;display: flex;flex-direction: column;background-color: white;border-radius: 20px;z-index: 999;">
        <div style="width: 100%;padding: 10px;display: flex;flex-direction: column;justify-content: center;align-items: center;" @click="clickJumpPage('Collect')">
          <star-filled :style="{
          fontSize: '25px',
          color: '#E74E4B'
        }" />
          <div style="color:#E74E4B;font-size: 12px;margin-top: 5px;">我的收藏</div>
        </div>
        <div style="width: 100%;padding: 10px;display: flex;flex-direction: column;justify-content: center;align-items: center;margin-top: 20px;"  @click="clickJumpPage('Plan')">
          <thunderbolt-filled cloud-outlined :style="{
          fontSize: '25px',
          color: '#E74E4B'
        }" />
          <div style="color:#E74E4B;font-size: 12px;margin-top: 5px;">自动蹲号</div>
        </div>
        <div style="width: 100%;padding: 10px;display: flex;flex-direction: column;justify-content: center;align-items: center;margin-top: 20px;" @click="clickJump('https://jq.qq.com/?_wv=1027&k=mFC9NwsK')" >
          <message-filled :style="{
          fontSize: '25px',
          color: '#E74E4B'
        }" />
          <div style="color:#E74E4B;font-size: 12px;margin-top: 5px;">反馈建议</div>
        </div>
        <div style="width: 100%;padding: 10px;display: flex;flex-direction: column;justify-content: center;align-items: center;margin-top: 20px;" @click="clickJump('https://jq.qq.com/?_wv=1027&k=txyC5Eow')">
          <heart-filled :style="{
          fontSize: '25px',
          color: '#E74E4B'
        }" />
          <div style="color:#E74E4B;font-size: 12px;margin-top: 5px;">WBL蹲号</div>
        </div>
        <div style="width: 100%;padding: 10px;display: flex;flex-direction: column;justify-content: center;align-items: center;margin-top: 20px;" @click="jumpTop()">
          <to-top-outlined :style="{
          fontSize: '25px',
          color: '#E74E4B'
        }" />
          <div style="color:#E74E4B;font-size: 12px;margin-top: 5px;">回到顶部</div>
        </div>
      </div>

    </div>
  </a-config-provider>


</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import Header from "@/components/layout/Header"
import Footer from "@/components/layout/Footer"
import {
  StarFilled,
  ThunderboltFilled,
  MessageFilled,
  HeartFilled,
  ToTopOutlined,
} from '@ant-design/icons-vue';

export default {
  name: "CommonLayout",
  components: {
    Header,
    Footer,
    StarFilled,
    ThunderboltFilled,
    MessageFilled,
    HeartFilled,
    ToTopOutlined,
  },
  data() {
    return {
      locale: zhCN,
    };
  },
  methods: {
    clickJump (url) {
      window.open(url, '_blank');
    },
    clickJumpPage(currentComponent) {
      console.log(currentComponent)
      this.$router.push({
        path: '/profile',
        query: {
          currentComponent: currentComponent
        }
      });
    },
    jumpTop() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }


}
</script>

<style scoped>

</style>
<style>
.ant-divider-horizontal {
  margin: 0!important;
}
</style>